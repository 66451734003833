@import 'styles/import.scss';

.text {
  @include heading-6;
  color: $blue_dark;
  margin-bottom: 64px;
}

.textWrapper {
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    margin-left: auto;
    border-left: 1px solid $grey25;
  }
}

.textContainer {
  padding: 80px 0;
  @include media-breakpoint-up(md) {
    padding: 120px;
  }
}

.intro {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row-reverse;
  }
}
