@import 'styles/import.scss';

.circle {
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.circleContainer {
  g {
    transform-origin: 50% 50%;
    transition: all 1s ease-in-out;
    opacity: 1;
    transform: rotate(0deg);

    &:nth-child(even) {
      transform: rotate(90deg);
      opacity: 0.5;
    }
  }
  &:hover {
    cursor: pointer;
    g {
      transform: rotate(90deg);
      opacity: 0.5;
      &:nth-child(even) {
        transform: rotate(0deg);
        opacity: 1;
      }
    }
  }
}
