@import 'styles/import.scss';

.visual {
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
}

.round:after,
.round:before {
  border: 1px solid $blue_light;
  border-radius: 50%;
  content: '';
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 1s ease-in-out, width 1s ease-in-out,
    height 1s ease-in-out;
}

.round:before {
  height: 100%;
  opacity: 1;
  width: 100%;
}

.round:after {
  height: 75%;
  opacity: 0;
  width: 75%;
}

.round:hover:before {
  height: 150%;
  opacity: 0;
  width: 150%;
}

.round:hover:after {
  height: 100%;
  opacity: 1;
  width: 100%;
}

.wrapper {
  position: relative;
  width: 86px;
  height: 86px;
}

.circle {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
