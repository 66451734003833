/**
 * Variables
 */

/**
 * Defaults
 */

$font-family-base: 'Roboto';
$font-size-base: '16px';

$white: #fff;
$black: #0e0e0f;
$chinese_black: #171715;
$grey300: #57606e;
$grey200: #697586;
$grey100: #9aa4b2;
$grey50: #bdc3cc;
$grey25: #d7dde5;
$grey10: #e6e9ec;
$grey5: #f3f4f6;
$grey1: #f9fafb;

$red_error: #ec0505;

//primary blue

$blue_spot: #bfd5ff;
$blue_light: #0567ec;
$blue: #053791;
$blue_dark: #051e4e;

//primary green
$green_spot: #6ddea8;
$green_light: #2fbd77;
$green: #147b48;
$green_dark: #043219;

$light-blue: #bdcae9;
$mid-blue: #9faac6;
$black-light: #6b6b6b;
$error: #cd0000;
$positive: #00e276;
$lotion: #fafafa;

//
$selection: rgba(191, 213, 255, 0.4);

$p1: #61c3d1;
$p2: #f26419;
$s1: #55abb8;
$s2: #1f3544;
$s3: #5c6870;
$s4: #a1b0ba;
$s5: #dbeced;
$su2: #162631;
$t1: #1e2850;
$n1: #afafaf;
$n2: #f9f9f9;
$su1: #dd4c00;

$linear-gradient: linear-gradient(
  104.47deg,
  #dbeced 0%,
  rgb(219 236 237 / 28%) 100.42%
);

$teaser-gradient: linear-gradient(
  90deg,
  #f9f9f7 0%,
  #f3faf9 29.1%,
  #edf5f9 56.27%,
  #eff1f9 100%
);
$shadow: 0 4px 40px rgb(0 0 0 / 8%);

$container-max-width: 1600px;
$container-outer-max-width: 1600px;

$header-height-sm: 72px;
$header-height: 88px;

$gutter-width: 16px;
$grid-gutter-width: 32px;
$grid-columns: 12;

$slider-button-size: 48px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1133px,
  xl: 1600px
);

$enable-grid-classes: false;
