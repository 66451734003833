$font-base: messinasans;
$font-mono: messinasansmono;

@font-face {
  font-family: MessinaSans;
  src: url('../../fonts/MessinaSans-SemiBold.woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: MessinaSans;
  src: url('../../fonts/MessinaSans-Regular.woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: MessinaSansMono;
  src: url('../../fonts/MessinaSansMono-Regular.woff2');
  font-weight: 400;
  font-style: normal;
}

@mixin link {
  border-bottom: 1px solid $green_light;
  display: inline-block;
  color: inherit;
}

@mixin heading-1 {
  letter-spacing: -0.02em;
  font-size: 51px;
  line-height: 60px;
  @include media-breakpoint-up(md) {
    font-size: 57px;
    line-height: 66px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 63px;
    line-height: 72px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 65px;
    line-height: 74px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 67px;
    line-height: 76px;
  }
}

@mixin heading-2 {
  letter-spacing: -0.02em;
  font-size: 43px;
  line-height: 53px;
  @include media-breakpoint-up(md) {
    font-size: 49px;
    line-height: 58px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 55px;
    line-height: 64px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 57px;
    line-height: 66px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 59px;
    line-height: 68px;
  }
}

@mixin heading-3 {
  letter-spacing: -0.02em;
  font-size: 39px;
  line-height: 48px;
  @include media-breakpoint-up(md) {
    font-size: 43px;
    line-height: 52px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 47px;
    line-height: 56px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 49px;
    line-height: 58px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 51px;
    line-height: 60px;
  }
}

@mixin heading-4 {
  letter-spacing: -0.02em;
  font-size: 35px;
  line-height: 44px;
  @include media-breakpoint-up(md) {
    font-size: 39px;
    line-height: 48px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 41px;
    line-height: 50px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 43px;
    line-height: 52px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 45px;
    line-height: 54px;
  }
}

@mixin heading-5 {
  letter-spacing: -0.01em;
  font-size: 31px;
  line-height: 40px;
  @include media-breakpoint-up(md) {
    font-size: 33px;
    line-height: 42px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 35px;
    line-height: 44px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 37px;
    line-height: 46px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 39px;
    line-height: 48px;
  }
}

@mixin heading-6 {
  letter-spacing: -0.005em;
  font-size: 27px;
  line-height: 36px;
  @include media-breakpoint-up(md) {
    font-size: 28px;
    line-height: 37px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 29px;
    line-height: 38px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 31px;
    line-height: 40px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 33px;
    line-height: 42px;
  }
}

@mixin text-xl {
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.005em;

  @include media-breakpoint-up(md) {
    font-size: 25px;
    line-height: 35px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 25px;
    line-height: 36px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 26px;
    line-height: 37px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 27px;
    line-height: 38px;
  }
}

@mixin text-lg {
  letter-spacing: -0.0025em;
  font-size: 20px;
  line-height: 31px;

  @include media-breakpoint-up(lg) {
    font-size: 21px;
    line-height: 32px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 22px;
    line-height: 33px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 23px;
    line-height: 34px;
  }
}

@mixin text-md {
  font-size: 17px;
  line-height: 28px;
}

@mixin text-sm {
  font-size: 15px;
  line-height: 26px;
}

@mixin text-xs {
  font-size: 13px;
  line-height: 21px;
}

//////
@mixin caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
}

@mixin caption-b {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.32px;
}

@mixin body-m {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
}

@mixin body-l {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin body-xl {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

@mixin heading-xs {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.16px;
}

@mixin heading-s {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

@mixin heading-m {
  font-size: 20px;
  line-height: 26px;
}

@mixin heading-l {
  font-size: 28px;
  line-height: 36px;
}

@mixin heading-xl {
  font-size: 32px;
  line-height: 40px;
}

@mixin display-l {
  font-size: 42px;
  font-weight: 300;
  line-height: 50px;
}

@mixin display-xl {
  font-size: 54px;
  font-weight: 300;
  line-height: 64px;
}

@mixin display-xxl {
  font-size: 72px;
  font-weight: 300;
  line-height: 96px;
}

@mixin font-family {
  font-family: $font-base;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}
