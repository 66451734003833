@import 'styles/import.scss';

.grid:hover:after {
  background-color: $blue_light;
  transition: background-color 0.2s cubic-bezier(0.25, 0.04, 0, 1);
}

.grid {
  align-items: center;
  aspect-ratio: 1;
  display: flex;
  grid-column: span 1;
  grid-row: span 1;
  justify-content: center;
  position: relative;
}

.grid:after {
  aspect-ratio: 1;
  border: 1px solid $blue_light;
  border-radius: 50%;
  content: '';
  position: absolute;
  transition: background-color 0.8s cubic-bezier(0.25, 0.04, 0, 1);
  width: 37.5%;
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  width: 152px;
}

.imageAnimationWrapper {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.animation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
